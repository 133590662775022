body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  from { border-color: transparent }
  to { border-color: white; }
}
